require('./bootstrap');
var numeral = require("numeral");

window.Vue = require('vue');

Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,0"); 
});

Vue.filter("formatNumberDecimal", function (value) {
    return numeral(value).format("0,0.00"); 
});

import ArrowComponent from './components/Arrow.vue';
import SignupComponent from './components/Signup.vue';
import CalculatorComponent from './components/Calculator.vue';
import DeleteButtonComponent from './components/DeleteButton.vue';

Vue.component('arrow', ArrowComponent);
Vue.component('signup-form', SignupComponent);
Vue.component('calculator', CalculatorComponent);
Vue.component('delete-button', DeleteButtonComponent);

var app = new Vue({
    el: '#app'
});