
<template>
<button @click="deleteAction" class="btn btn-danger" type="button">Delete</button>
</template>

<script>
    export default {
        props: ['id', 'type', 'customer', 'redirect'],
        data: () => ({
        }),
        methods: {
            deleteAction() {
                if(confirm("Are you sure you want to delete this " + this.type +"?")) {

                    var endpoint = '';
                    switch(this.type) {
                        case 'file':
                            endpoint = '/admin/customers/delete-resource';
                            break;

                        case 'nomination':
                            endpoint = '/customer/nominations/'+this.id+'/delete';
                            break;

                        case 'user':
                            endpoint = '/admin/customers/'+this.customer+'/users/'+this.id+'/delete';
                            break;

                        case 'admin':
                            endpoint = '/admin/settings/users/'+this.id+'/delete';
                            break;
                    }

                    if(endpoint != '') {
                        axios.post(endpoint, { id: this.id })
                            .then(data => {
                                if(this.redirect) {
                                    window.location.href = this.redirect;                               
                                } else {
                                    window.location.reload();
                                }
                            });
                    } else {
                        alert('Invalid resource type');
                    }
                }
            }
        } 
    }
</script>