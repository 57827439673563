<template>

<div class="signup-form">
    <label><input type="checkbox" v-model="terms"  value="accept" id="chk_terms" required  true-value="yes" false-value="no">&nbsp;&nbsp;I consent to my personal and confidential information being collected, used and disclosed in accordance with the above Collection Statement.</label>

    <div class="text-right mb-4">
        <button type="button" class="btn btn-primary" :disabled="terms=='no' || loading"  v-on:click="submitForm()">{{ submitBtnText }} <arrow></arrow></button>
    </div>

</div>

</template>

<script>
    export default {
        props: ['uuid'],
        data: () => {
            return {
                terms: 'no',
                submitBtnText: 'Submit',
                loading: false
            }
        },
        methods: {
            submitForm: function () {
                this.loading = true;
                this.submitBtnText = 'Submitting...';
                $('#signup-form').submit();
            }
        }
    }
</script>
