window._ = require("lodash");

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require("jquery");

    require("bootstrap-sass");
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-CSRF-TOKEN"] = window.Laravel.csrfToken;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: 'your-pusher-key'
// });

$(function () {
    var ww = $(window).width();
    var wh = $(window).height();

    function init() {
        // #region Global
        "use strict";
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName("needs-validation");
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener(
                "submit",
                function (event) {
                    if (form.checkValidity() === false) {
                        event.preventDefault();
                        event.stopPropagation();
                    } else {
                        form.classList.add("valid");
                    }
                    form.classList.add("was-validated");
                },
                false
            );
        });

        function goTo(target, offsetTop) {
            offsetTop = offsetTop || 0;
            var navbarHeight = $(".navbar").outerHeight();
            if (ww > 576) navbarHeight = 70;
            $("html, body")
                .stop()
                .animate(
                    {
                        scrollTop:
                            $(target).offset().top - navbarHeight + offsetTop,
                    },
                    500
                );
        }

        $(".scrollto").click(function (e) {
            e.preventDefault();
            var offsetTop = 0;
            var attr = $(this).attr("data-offset");
            if (typeof attr !== typeof undefined && attr !== false) {
                offsetTop = Number(attr);
            }
            goTo($(this).attr("href"), offsetTop);
        });

        $(window)
            .on("resize", function () {
                ww = $(window).width();
                wh = $(window).height();

                $(".full-height").height(wh);

                if (ww < 1200) {
                    $(".navigation").show();
                } else {
                    closeNavigation();
                }
            })
            .trigger("resize");

        $("body").on("click", ".dropdown-select .dropdown-item", function (e) {
            e.preventDefault();
            var val = $(this).text(),
                target = $(this).parents(".dropdown-select");
            target.find(".dropdown-toggle").text(val);
        });

        // #endregion

        // #region Navbar
        $(document).bind("ready scroll", function () {
            if ($(document).scrollTop() >= 10) {
                $(".navbar").addClass("scrolled");
            } else {
                $(".navbar").removeClass("scrolled");
            }
        });

        // if (ww > 1199) {
        //     $(".navbar-affix").affix({
        //         offset: {
        //             top: $(".topbar").outerHeight(),
        //         },
        //     });
        // }

        function toggleDropdown(e) {
            const _d = $(e.target).closest(".dropdown"),
                _m = $(".dropdown-menu", _d);
            setTimeout(
                function () {
                    const shouldOpen = e.type !== "click" && _d.is(":hover");
                    _m.toggleClass("show", shouldOpen);
                    _d.toggleClass("show", shouldOpen);
                    $('[data-toggle="dropdown"]', _d).attr(
                        "aria-expanded",
                        shouldOpen
                    );
                },
                e.type === "mouseleave" ? 10 : 0
            );
        }
        $("body")
            .on(
                "mouseenter mouseleave",
                ".dropdown:not(.dropdown-select):not(.select-dropdown)",
                toggleDropdown
            )
            .on(
                "click",
                ".dropdown:not(.dropdown-select):not(.select-dropdown) .dropdown-menu a",
                toggleDropdown
            );

        $('[data-toggle="navigation"]').click(function () {
            if ($(this).hasClass("is-active")) {
                closeNavigation();
            } else {
                $(this).addClass("is-active");
                $("body").addClass("navigation-open");
            }
        });
        $('[data-dismiss="navigation"]').click(function () {
            closeNavigation();
        });
        function closeNavigation() {
            $('[data-toggle="navigation"]').removeClass("is-active");
            $("body").removeClass("navigation-open");
        }

        $(".navbar-search .btn-search").click(function (e) {
            var target = $(".navbar-search");
            if (!target.hasClass("active")) {
                target.addClass("active");
                if (
                    !$("html").hasClass("ipad") &&
                    !$("html").hasClass("iphone")
                ) {
                    setTimeout(function () {
                        target.find(".form-control").focus();
                    }, 500);
                }
                e.preventDefault();
                e.stopPropagation();
            }
        });
        $(".navbar-search").click(function (e) {
            e.stopPropagation();
        });
        $("body").click(function () {
            $(".navbar-search .form-control").val("");
            $(".navbar-search").removeClass("active");
        });

        $(".navigation .scrollto").click(function () {
            closeNavigation();
        });
        // #endregion

        // #region Homepage
        if ($("#bgVideo").length > 0) $("#bgVideo").YTPlayer();

        $('[data-toggle="video"]').click(function (e) {
            e.preventDefault();
            $("#bgVideo").YTPPause();
            $("#modal-video")
                .find(".video-placehere")
                .attr("src", $(this).data("video"));
            $("#modal-video").modal("show");
        });
        $("#modal-video").on("hide.bs.modal", function (event) {
            $("#modal-video").find(".video-placehere").attr("src", "");
            $("#bgVideo").YTPPlay();
        });

        feather.replace()

        $('[data-toggle="team-detail"]').click(function (e) {
            e.preventDefault();
            var modal_id = $(this).data("modal-id");
            $("#" + modal_id).modal("show");
        });

        $(".collapse").on("hidden.bs.collapse", function () {
            AOS.refresh();
        });
        $(".collapse").on("shown.bs.collapse", function () {
            AOS.refresh();
        });
        $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
            AOS.refresh();
        });
        AOS.init({ offset: 0, duration: 700, delay: 100 });
        

        $("a.dropdown-category").on("click", function (e) {
            var category = $(this).data("value");
            if (category === "") {
                $("select[name=category] option:first-child").attr(
                    "selected",
                    "selected"
                );
            } else {
                $("select[name=category] option[value=" + category + "]").attr(
                    "selected",
                    "selected"
                );
            }
            $("form#document-filter").submit();
        });
        $("a.dropdown-type").on("click", function (e) {
            var type = $(this).data("value");
            if (type === "") {
                $("select[name=type] option:first-child").attr(
                    "selected",
                    "selected"
                );
            } else {
                $("select[name=type] option[value=" + type + "]").attr(
                    "selected",
                    "selected"
                );
            }
            $("form#document-filter").submit();
        });
        $("a.dropdown-year").on("click", function (e) {
            var year = $(this).data("value");
            if (year === "") {
                $("select[name=year] option:first-child").attr(
                    "selected",
                    "selected"
                );
            } else {
                $("select[name=year] option[value=" + year + "]").attr(
                    "selected",
                    "selected"
                );
            }

            $("form#document-filter").submit();
        });

    }

    init();
});