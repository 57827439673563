<template>
    <svg width="19" height="19" class="icon-arrow-right ml-3"><use xlink:href="/img/icons.svg#icon-arrow-down"></use></svg>
</template>

<script>
    export default {
        mounted() {
        }
    }
</script>
