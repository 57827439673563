<template>
<div class="calculator">
    <h4 class="mb-3">Calculator</h4>
    <p>Please use one of the following options to help you estimate how much home electricity you typically consume during one year.</p>
    <div class="container">
        <div class="card-deck mb-3 text-center" >
            <div class="card mb-4 shadow-sm" v-on:click="selectOption('household size')" v-bind:class="[option=='household size' ? 'active' : 'inactive' ]">
                <div class="card-header text-left">
                    <p class="my-0">How much electricity do I use?</p>
                    <!-- <p class="sub-header">Select a household size average, to easily nominate how much electricity you use or nominate a specific amount.</p> -->
                </div>
                <div class="card-body pt-0 text-left">
                    <!-- <div class="row">
                        <label class="form-check-label" for="size_xl">
                            <input class="form-check-input" type="radio" name="size" id="size_xl" v-model="size" value="20"  :disabled="option!='household size'" @change="calculate()" required>
                            XL (20 MWh per annum)
                        </label>
                    </div> -->
                    <div class="row">
                        <label class="form-check-label text-bold" for="size_big">
                            <input class="form-check-input" type="radio" name="size" id="size_big" v-model="size" value="12"  :disabled="option!='household size'" @change="calculate()" required>
                            <img src="/img/large.svg" class="option-icon"/>Large multi storey 4+ bedroom home
                            <p class="option-help">12 MWh or 12,000 kWh per annum<br/>Big home electricity usage is typical for multi-story homes with four or more bedrooms and air conditioning – often with a swimming pool but without solar panels.</p>
                        </label>
                        
                    </div>
                    <div class="row">
                        <label class="form-check-label text-bold" for="size_avg">
                            <input class="form-check-input" type="radio" name="size" v-model="size" id="size_avg" value="5" :disabled="option!='household size'" @change="calculate()" required>
                            <img src="/img/double.svg" class="option-icon"/>Average 3 bedroom home
                            <p class="option-help">5 MWh or 5,000 kWh per annum<br/>Average home electricity usage is typical for homes up to three bedrooms, often on one level with air-conditioning and without a pool or solar panels.</p>
                        </label>
                    </div>
                    <div class="row">
                        <label class="form-check-label text-bold" for="size_small">
                            <input class="form-check-input" type="radio" name="size" v-model="size" id="size_small" value="2" :disabled="option!='household size'" @change="calculate()" required>
                            <img src="/img/small.svg" class="option-icon"/>Small single storey 1-2 bedroom home
                            <p class="option-help">2 MWh or 2,000 kWh per annum<br/>Small home electricity usage is typical for homes with two bedrooms or less, often on a single story, with gas appliances and limited use of air-conditioning and without solar panels.</p>
                        </label>
                    </div>
                    <div class="row">
                        <label class="form-check-label text-bold" for="size_nominate">
                            <input class="form-check-input" type="radio" name="size" v-model="size" id="size_nominate" value="0" :disabled="option!='household size'" @change="calculate()" required>
                            <img src="/img/edit.png" class="option-icon"/>Nominate a specific amount of electricity
                        </label>
                        <div class="col-12">
                            <div class="row px-3 pt-0">
                                <div class="col-4" v-if="size == 0">
                                    <div class="row p-2">
                                        <label for="company">I use about</label>
                                        <input
                                            type="number"
                                            class="form-control"
                                            id="usage"
                                            name="your_usage"
                                            min="1"
                                            max="99999999"
                                            onkeydown="return event.keyCode !== 69 && event.keyCode !== 187 && event.keyCode !== 189 && event.keyCode !== 107 && event.keyCode !== 109"
                                            v-model="usage"
                                            placeholder="Your usage (enter number)"
                                            required
                                            v-on:input="calculate()" 
                                        />
                                    </div>
                                </div>
                                <div class="col-4" v-if="size == 0">
                                    <div class="row p-2">
                                        <label for="company">Units</label>
                                        
                                        <select v-model="units" class="form-control" @change="calculate()" >
                                            <option>kWh</option>
                                            <option>MWh</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-4" v-if="size == 0">
                                    <div class="row p-2">
                                        <label>Period</label>
                                        <select v-model="usagePeriod" class="form-control" @change="calculate()" >
                                            <option>day</option>
                                            <option>week</option>
                                            <option>month</option>
                                            <option>quarter</option>
                                            <option>year</option>
                                        </select>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="or-separator">OR</div>
            <div class="card mb-4 shadow-sm" v-on:click="selectOption('usage')" v-bind:class="[option=='usage' ? 'active' : 'inactive' ]">
                <div class="card-header text-left">
                    <p class="my-0">Nominate a specific amount of electricity</p>
                </div>
                <div class="card-body pt-0">
                    <div class="row">
                        <label for="company">Units</label>
                        
                        <select v-model="units" class="form-control" :disabled="option!='usage'" @change="calculate()" >
                            <option>kWh</option>
                            <option>MWh</option>
                        </select>
                    </div>
                    <div class="row">
                        <label>Period</label>
                        <select v-model="usagePeriod" class="form-control" :disabled="option!='usage'"  @change="calculate()" >
                            <option>day</option>
                            <option>week</option>
                            <option>month</option>
                            <option>quarter</option>
                            <option>year</option>
                        </select>
                    </div>
                    <div class="row">
                        <label for="company">I use about</label>
                        <input
                            type="number"
                            class="form-control"
                            id="usage"
                            name="your_usage"
                            min="1"
                            max="99999999"
                            onkeydown="return event.keyCode !== 69 && event.keyCode !== 187 && event.keyCode !== 189 && event.keyCode !== 107 && event.keyCode !== 109"
                            v-model="usage"
                            :disabled="option!='usage'"
                            placeholder="Your usage (enter number)"
                            required
                            v-on:input="calculate()" 
                        />
                    </div>
                    
                </div>
            </div> -->
            <!-- <div class="card mb-4 shadow-sm" v-on:click="selectOption('spend')" v-bind:class="[option=='spend' ? 'active' : 'inactive' ]">
                <div class="card-header text-left">
                    <p class="my-0">Option 2 - I know my spend</p>
                </div>
                <div class="card-body pt-0">
                    <div class="row">
                        <label>My bill every</label>
                        <select v-model="billPeriod" class="form-control" :disabled="option!='spend'"  @change="calculate()" >
                            <option>month</option>
                            <option>quarter</option>
                            <option>year</option>
                        </select>
                    </div>
                    <div class="row">
                        <label>is about</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <input type="number" min="1" class="form-control" name="your_spend" onkeydown="return event.keyCode !== 69 && event.keyCode !== 187 && event.keyCode !== 189 && event.keyCode !== 107 && event.keyCode !== 109" placeholder="Your spend (enter number)" required v-model="spend"  v-on:input="calculate()" :disabled="option!='spend'">
                        </div>
                        
                    </div>
                    <div class="row">
                        <label>and I live in</label>
                        <select v-model="state" class="form-control"  :disabled="option!='spend'" @change="calculate()" >
                            <option>NSW</option>
                            <option>VIC</option>
                            <option>QLD</option>
                            <option>ACT</option>
                            <option>TAS</option>
                            <option>WA</option>
                            <option>SA</option>
                            <option>NT</option>
                        </select>
                    </div>
                </div>
            </div> -->
            
        </div>
    </div>
    <div  v-if="calculatedCost > 0">
        <hr class="mb-4" />
        <h4 class="mb-3">Calculated Result - based on your {{ size > 0 ? 'household size' : 'usage' }}</h4>
        <p>Here is your calculated result based on the option you selected and the information you provided above. You may choose to adjust the option you selected and the information you provided above to arrive at a result that is higher or lower than your estimated annual electricity usage.</p>
        <!-- <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-3 mb-3">
                <label for="company" class="calculated-box-label">Your nominated<br/>electricity usage</label>
                <div class="calculated-box">{{calculatedUsage | formatNumber }} MWh per annum</div>
                <input type="hidden" name="usage" v-model="calculatedUsage"/>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3 mb-3">
                <label for="company" class="calculated-box-label">Total cost to offset your<br/>nominated electricity usage</label>
                <div class="calculated-box">${{ calculatedCost | formatNumber}} for one year</div>
                
            </div>
        </div> -->
        <div class="alert alert-danger" v-if="overcap"><strong>Warning</strong><p>Your estimated annual electricity usage amount exceeds the maximum limit of {{ meta.customer.cap }} MWh. Please check your {{option}} numbers above.</p></div>

        <!-- <hr class="mb-4"  v-if="calculatedCost > 0" /> -->
        <h4 class="mb-3" v-if="calculatedCost > 0">Estimated Usage</h4>
        <p class="mb-4"  v-if="calculatedCost > 0">Estimated Usage: {{calculatedUsage | formatNumber }} MWh</p>
        
        <div class="row mb-4" v-if="calculatedCost > 0">
            <div class="col">
                <table>
                    <tr v-if="employerContribution > 0">
                        <td valign="top">Total cost to offset your Estimated Usage</td>
                        <td valign="top" align="right">${{ totalCost | formatNumberDecimal}}</td>
                    </tr>
                    <tr class="" style="color: red; border-bottom: 1px solid black;" v-if="employerContribution > 0">
                        <td valign="top">Deduction of your employer’s contribution amount</td>
                        <td valign="top" align="right">-${{ employerContribution | formatNumberDecimal}}</td>
                    </tr>
                    <tr>
                        <td valign="top" style="padding-right: 40px;"><strong>Total cost to you to offset your Estimated Usage<br/>(Staff Contribution Amount)</strong></td>
                        <td valign="top" align="right"><strong>${{ calculatedCost | formatNumberDecimal}}</strong></td>
                    </tr>
                </table>
                <small>* all amounts are including GST</small>
            </div>
        </div>
    </div>
    <hr class="mb-4" />
    <p>If you would like to nominate the Estimated Usage above as your Nominated Electricity Usage for the program year, then please click “Submit” and proceed to the confirmation page.</p>
    <div class="text-right mb-4">
        <button class="btn btn-primary" :disabled='calculatedCost < 1 || overcap' type="button" data-toggle="modal" data-target="#exampleModal">Submit <arrow></arrow></button>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <h4 class="mb-3">Nominated Electricity Usage</h4>
            <div class="row mb-3">
                <div class="col-sm-6 col-md-4 col-lg-3 mb-3">
                    <label for="company" class="calculated-box-label">Your Nominated<br/>Electricity Usage</label>
                    <div class="calculated-box">{{calculatedUsage | formatNumber }} MWh for the program year</div>
                    <input type="hidden" name="usage" v-model="calculatedUsage"/>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3 mb-3">
                    <label for="company" class="calculated-box-label">Your Staff<br/>Contribution Amount</label>
                    <div class="calculated-box">${{ calculatedCost | formatNumberDecimal}} for the program year</div>
                </div>
            </div>

            <div id="terms">
                <h4 class="mb-2">Overview, Disclosures, Terms & Conditions</h4>
                <div class="termsx" v-if="meta && meta.customer && meta.customer.terms">
                    <p v-html="meta.customer.terms"></p>
                </div>
            </div>
            <div style="text-align:right"><a href="#" onclick="printTerms()">Print</a></div>
            <hr class="mb-4" />
            <div class="form-checkz">
                <!-- By clicking “Accept” below you agree with your Employer that:
                <ul>
                    <li>you have read and understood the overview provided above in relation to how GtT operates;</li>
                    <li>you have read and understood, and acknowledge and accept, the disclosures, terms and conditions set out above;</li>
                    <li>you agree to participate in GtT on the basis outlined above; and</li>
                    <li>you agree to your Employer deducting the Employee Contribution Amount from your post tax salary (in a single deduction) as displayed on this web page above.</li>
                </ul> -->

                <input class="form-check-input" type="checkbox" v-model="terms"  value="accept" id="chk_terms" required  true-value="yes" false-value="no">
                <label class="form-check-label" for="chk_terms">
                    I accept the above overview, disclosures, terms and conditions.
                </label>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light float-left" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-light float-left" onclick="printTerms()">Print Overview, Disclosures, Terms & Conditions</button>
            <button type="button" class="btn btn-primary" :disabled="terms=='no' || loading"  v-on:click="submitForm()">{{ submitBtnText }} <arrow></arrow></button>
        </div>
        </div>
    </div>
    </div>
</div>

</template>

<script>
    export default {
        props: ['uuid'],
        data: () => {
            return {
                option: 'household size',
                usage: '',
                spend: '',
                size: 5,
                meta: {},
                units: 'kWh',
                usagePeriod: 'day',
                billPeriod: 'quarter',
                state: 'NSW',
                calculatedUsage: 0,
                calculatedCost: 0,
                totalCost: 0,
                employerContribution: 0,
                overcap: false,
                terms: 'no',
                submitBtnText: 'Accept',
                loading: false
            }
        },
        mounted() {
            fetch('/api/metadata?uuid='+ this.uuid)
                .then(response => response.json())
                .then(data => {
                    this.meta = data;
                    this.calculate();
                });
        },
        methods: {
            selectOption: function(option) {
                this.option = option;
                this.resetCalc();
                this.calculate();
            },
            resetCalc: function() {
                this.calculatedCost = 0;
                this.calculatedUsage = 0;
                this.overcap = false;
            },
            submitForm: function () {
                this.loading = true;
                this.submitBtnText = 'Submitting...';
                $('#nomination-form').submit();
            },
            calculate: function () {
                
                switch(this.option) {

                    case "household size":
                        if(this.size > 0) {
                            this.calculatedUsage = Math.round(this.size).toFixed(0);
                            this.calculatedCost = Number(this.size * this.meta.customer.price * (1-this.meta.customer.offset/100)).toFixed(2);
                            this.totalCost = Number(this.calculatedUsage * this.meta.customer.price).toFixed(2);
                            this.employerContribution = Number(this.totalCost - this.calculatedCost).toFixed(2);
                        } else {
                            let unitsPerPriod = (this.units == 'kWh') ? 0.001 : 1;
                            let period = 365;
                            switch(this.usagePeriod) {
                                case "day":
                                    period = 365;
                                    break;
                                case "week":
                                    period = 52.14;
                                    break;
                                case "month":
                                    period = 12;
                                    break;
                                case "quarter":
                                    period = 4;
                                    break;
                                case "year":
                                    period = 1;
                                    break;
                            }

                            if(this.usage < 0) {
                                this.usage = this.usage * (-1) ;
                            }
                            
                            this.calculatedUsage = Math.round(this.usage * unitsPerPriod * period).toFixed(0);
                            if(this.calculatedUsage == 0) {
                                this.calculatedUsage = 1; //set to 1MWh minimum
                            }
                            this.calculatedCost = Number(this.calculatedUsage * this.meta.customer.price * (1-this.meta.customer.offset/100)).toFixed(2);
                            this.totalCost = Number(this.calculatedUsage * this.meta.customer.price).toFixed(2);
                            this.employerContribution = Number(this.totalCost - this.calculatedCost).toFixed(2);
                        }
                        break;

                    case "spend":
                        let spendPeriod = 4;
                        let localPrice = this.meta.app.localPrice[this.state];

                        switch(this.billPeriod) {
                            case "month":
                                spendPeriod = 12;
                                break;
                            case "quarter":
                                spendPeriod = 4;
                                break;
                            case "year":
                                spendPeriod = 1;
                                break;
                        }
                        if(this.spend < 0) {
                            this.spend = this.spend * (-1) ;
                        }
                        this.calculatedUsage = Math.round(this.spend * spendPeriod / localPrice).toFixed(0) ;
                        this.calculatedCost = Math.round(this.calculatedUsage * this.meta.customer.price * (1-this.meta.customer.offset/100)).toFixed(0);
                        this.totalCost = Math.round(this.calculatedUsage * this.meta.customer.price);
                        this.employerContribution = Math.round(this.totalCost - this.calculatedCost);
                        break;
                }

                if(this.calculatedUsage > parseInt(this.meta.customer.cap)) {
                    this.overcap = true;
                } else {
                    this.overcap = false;
                }
            }
        }
    }
</script>
